import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Table } from 'reactstrap'

import model from 'src/model'

/**
 * @param {{list?: any[], fieldsDescription: any, className?: any, actions?: any, style?: any, hover?: boolean, control?: (item) => ReactNode }}
 */
const List = ({
  list,
  className,
  style,
  fieldsDescription,
  hover,
  actions,
  control
}) => {
  const { t } = useTranslation()

  return (
    <div className='table-responsive'>
      <Table
        className={className || 'table-sm align-middle mb-0'}
        hover={hover}
        style={style}
      >
        <thead>
          <tr>
            {Object.keys(fieldsDescription).map((key) => {
              return (
                <th
                  key={key}
                  className='pe-4'
                  scope='col'
                  onClick={fieldsDescription[key].onClick}
                  style={
                    fieldsDescription[key].rightAligned
                      ? { textAlign: 'end' }
                      : {}
                  }
                >
                  {`${t(
                    fieldsDescription[key].label !== undefined
                      ? fieldsDescription[key].label
                      : key
                  )}`}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {list.map((listItem, rank) => {
            return (
              <tr
                key={`${listItem.id}-${rank}`}
                onClick={listItem.onPress}
                role={listItem.onPress ? 'button' : 'none'}
              >
                {Object.keys(fieldsDescription).map((key) => (
                  <td
                    onClick={fieldsDescription[key].onClickValue}
                    key={`${listItem.id}-${rank}-${key}`}
                    align={
                      fieldsDescription[key].rightAligned ? 'right' : 'left'
                    }
                    className={`pe-4 ${
                      fieldsDescription[key].mappingClassName
                        ? fieldsDescription[key].mappingClassName(listItem[key])
                        : ''
                    }`}
                  >
                    {fieldsDescription[key].isLink ? (
                      <Link
                        to={
                          typeof fieldsDescription[key].mapLinkValue ===
                          'function'
                            ? fieldsDescription[key].mapLinkValue(listItem)
                            : listItem[fieldsDescription[key].linkFieldName]
                        }
                        className='fw-medium'
                      >
                        {model.fieldValueToText(
                          key,
                          listItem,
                          fieldsDescription
                        )}
                      </Link>
                    ) : fieldsDescription[key].isButton ? (
                      <div
                        className='text-primary fw-medium cursor-pointer'
                        onClick={() =>
                          fieldsDescription[key].onClick(listItem, rank)
                        }
                      >
                        {model.fieldValueToText(
                          key,
                          listItem,
                          fieldsDescription
                        )}
                      </div>
                    ) : (
                      model.fieldValueToText(key, listItem, fieldsDescription)
                    )}
                  </td>
                ))}
                {actions && <td>{actions[rank]}</td>}
                {control?.(listItem)}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default List
