import React, { useState } from 'react'

import UiContent from 'src/Components/Common/UiContent'

import { useTranslation } from 'react-i18next'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import Deployments from './Deployments'
import Pods from './Pods'
import ReplicaSet from './ReplicaSet'
import CronJobs from './CronJobs'

export const Monitoring = () => {
  const { t } = useTranslation()

  const [active, setActive] = useState(1)

  const title = t('Workload')
  document.title = title

  const handleActive = (id: number) => {
    setActive(id)
  }

  const monitoringTabs = [
    {
      title: 'Поды',
      content: <Pods />,
      id: 1
    },
    { title: 'Контроллеры Deployment', content: <Deployments />, id: 2 },
    {
      title: 'ReplicaSet',
      content: <ReplicaSet />,
      id: 3
    },
    {
      title: 'Cron-jobs',
      content: <CronJobs />,
      id: 4
    }
  ]

  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <Nav tabs className='nav-tabs nav-border-top mb-3'>
              {monitoringTabs.map(({ title, id }) => (
                <NavItem key={`nav-${id}`}>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                      active: active === id
                    })}
                    onClick={() => handleActive(id)}
                  >
                    {title}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            {monitoringTabs.map(({ content, id }) => (
              <div className='mb-3 mt-4' key={id}>
                {id === active && content}
              </div>
            ))}
          </CardBody>
        </Card>
      </div>
    </>
  )
}
