import { useTranslation } from 'react-i18next'

import { UnionClientEntity } from '../../../services/clients'

import Client from '../../../client'
import Tabs, { TabsItem } from '../../../Components/Common/Tabs'
import { useSelector } from 'src/store'

interface IRelatedProps {
  client: Partial<UnionClientEntity>
}

type ClientsTabs = {
  type?: string
  profileId?: string
  application?: IRelatedProps['client']
  profile_id?: string
}

type Item = ClientsTabs & TabsItem

const Related = ({ client }: IRelatedProps) => {
  //@ts-ignore
  const roles = useSelector((state) => state?.Login?.userInfo?.roles)
  const { t } = useTranslation()

  const items: Item[] = []

  if (client.addon_applications) {
    items.push({
      title: `${t('Applications')}`,
      entity: client.addon_applications,
      entityType: 'addon_applications',
      type: Client.ENTITY_TYPE.APPLICATIONS
    })
  }

  if (client.addon_loans) {
    items.push({
      title: `${t('Loans')}`,
      entity: client.addon_loans,
      entityType: 'addon_loans',
      type: Client.ENTITY_TYPE.LOANS
    })
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'KPK') {
    items.push({
      title: `${t('Membership history KPK')}`,
      entity: {},
      entityType: 'membership_history_kpk',
      profileId: client.profile_id
    })
  }

  if (client.addon_payments?.length) {
    items.push({
      title: `${t('Payments')}`,
      entity: client.addon_payments,
      entityType: 'addon_payments',
      type: Client.ENTITY_TYPE.PAYMENTS
    })
  }

  if (roles?.includes('audit_fssp') === false) {
    items.push({
      title: `${t('Notifications')}`,
      entityType: 'profile_notifications',
      entity: {},
      profile_id: client.profile_id
    })
  }

  items.push({
    title: `${t('DocumentsSigned')}`,
    entity: {},
    id: client.id,
    entityType: 'client_documents_signed'
  })

  if (roles?.includes('audit_fssp') === false) {
    items.push({
      title: `${t('tags')}`,
      entityId: client.id,
      entityType: 'clients_tags'
    })
  }

  items.push({
    title: `${t('Block_client')}`,
    entity: client,
    entityType: 'block_client',
    application: client
  })

  if (roles?.includes('audit_fssp') === false) {
    items.push({
      title: `${t('History claims')}`,
      entityId: client.id,
      profileId: client.profile_id,
      entityType: 'history_claims'
    })
  }

  return <Tabs<ClientsTabs> items={items} />
}

export default Related
