import { api, omitBlankEntries } from 'src/services/api'

interface KpkEventResponse {
  profile_id: string
  application_id: string
  created_at: string
  end_devent_typeate: number
  request_data: any //TODO: fix
  response_code: 200
  response_data: any //TODO: fix
  doc_num: string
  result_text: string
  result_status: number
}

export const kpkEventsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getKpkEvents: builder.query<KpkEventResponse[], any>({
      query: (loan_id) => `crm/v1/kpk/${loan_id}/kpk-events`
    }),
    resendKpkEvents: builder.mutation<any, any>({
      query: (params) => ({
        url: `/crm/v1/kpk/resend-kpk-events`,
        method: 'POST',
        params: omitBlankEntries(params)
      })
    })
  })
})

export const { useGetKpkEventsQuery, useResendKpkEventsMutation } = kpkEventsApi
