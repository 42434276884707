import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Flatpickr from 'react-flatpickr'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import { formatAPIDateTime, formatUIDate } from 'src/utils'
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
  Spinner
} from 'reactstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  useGetTransactionByIdQuery,
  useSetTransactionMutation
} from 'src/services/payments'
import { paymentsLink } from './constants'
import { UseQueryHookResultData } from 'src/services/api'
import { InputCompany } from './types'

export const PaymentCreatePage: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('loan_id') || ''

  const initials: InputCompany = {
    loan_id: { label: 'loan', initial: id || '', placeholder: '' },
    amount: { label: 'amount', initial: '', placeholder: '' },
    transaction_id: { label: 'transaction_id', initial: '', placeholder: '' },
    transaction_date: {
      label: 'transaction_date',
      initial: /*new Date()*/ '',
      placeholder: '',
      date: true
    },
    comment: { label: 'comment', initial: '', placeholder: '' }
  }
  const { data: payment } = useGetTransactionByIdQuery(id, {
    skip: !id
  })
  const [setTransaction, { isSuccess, isError, isLoading, error: errorText }] =
    useSetTransactionMutation()

  if (isSuccess) {
    toast.success(t('Operation success'), {
      onClose: () => navigate(paymentsLink)
    })
  }

  // const error = errorText as unknown as ApiError
  console.log(errorText)
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...Object.fromEntries(
        Object.keys(initials).map((key) => [
          key,
          initials[key as keyof typeof initials].initial
        ])
      ),
      ...payment
    } as UseQueryHookResultData<typeof useSetTransactionMutation>,
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      setTransaction(values)
    }
  })

  const pageTitle = t('Create_payment')
  document.title = pageTitle

  return (
    <>
      <>
        <UiContent />
        <div className='page-content'>
          <BreadCrumb title={pageTitle} />
          <Card>
            <CardBody>
              <MetaBar backLink={paymentsLink} />
              <Row className='mt-3'>
                <Form
                  className='needs-validation'
                  onSubmit={(e) => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row>
                    {Object.keys(initials).map((fieldKey) => {
                      const typedFieldKey = fieldKey
                      const htmlForId = `validation-${typedFieldKey}`
                      return (
                        <Col md='4' key={typedFieldKey}>
                          <FormGroup className='mb-3'>
                            <Label htmlFor={htmlForId}>
                              {t(initials[typedFieldKey].label)}
                            </Label>
                            {initials[typedFieldKey].options?.length ? (
                              <select
                                name={typedFieldKey}
                                id={htmlForId}
                                className='form-select form-select-sm'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values[typedFieldKey] || ''}
                              >
                                {initials[typedFieldKey].options?.map(
                                  (option) => (
                                    <option
                                      key={option.name}
                                      value={option.value}
                                    >
                                      {option.name}
                                    </option>
                                  )
                                )}
                              </select>
                            ) : initials[typedFieldKey].date ? (
                              <Flatpickr
                                name={typedFieldKey}
                                id={htmlForId}
                                className='form-control form-control-sm'
                                options={{
                                  enableTime: true,
                                  time_24hr: true,
                                  dateFormat: 'd-m-Y H:i',
                                  defaultDate: formatUIDate(
                                    initials[typedFieldKey].initial
                                  ),
                                  onChange: (selectedDates: Date[]) => {
                                    validation.setFieldValue(
                                      typedFieldKey as string,
                                      formatAPIDateTime(selectedDates[0])
                                    )
                                  }
                                }}
                              />
                            ) : (
                              <Input
                                name={typedFieldKey}
                                placeholder={
                                  initials[typedFieldKey].placeholder
                                }
                                type='text'
                                className='form-control form-control-sm'
                                id={htmlForId}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values[typedFieldKey] || ''}
                                invalid={
                                  !!(
                                    validation.touched[typedFieldKey] &&
                                    validation.errors[typedFieldKey]
                                  )
                                }
                              />
                            )}
                            {validation.touched[typedFieldKey] &&
                              validation.errors[typedFieldKey] && (
                                <FormFeedback>
                                  <Alert color='warning'>
                                    {
                                      <strong>
                                        {validation.errors[typedFieldKey]}
                                      </strong>
                                    }
                                  </Alert>
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>
                      )
                    })}
                  </Row>

                  {isError && errorText && (
                    <Alert color='warning'>
                      <strong> {JSON.stringify(errorText)} </strong>
                    </Alert>
                  )}

                  {isSuccess && (
                    <Alert color='success'>
                      <strong>{t('Created')}</strong>
                    </Alert>
                  )}

                  {isLoading ? (
                    <Spinner color='primary'>{t('Loading...')}</Spinner>
                  ) : (
                    <Button
                      className='text-nowrap'
                      color='primary'
                      type='submit'
                      disabled={!validation.dirty}
                    >
                      {t('Create')}
                    </Button>
                  )}
                </Form>
              </Row>
            </CardBody>
          </Card>
        </div>
      </>
    </>
  )
}
