import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Modal, ModalBody } from 'reactstrap'

interface EventsInfoProps {
  isOpen: boolean
  toggle: () => void
  currentEntity: any
}

export const EventsInfo: FC<EventsInfoProps> = ({
  isOpen = false,
  toggle,
  currentEntity
}) => {
  const { t } = useTranslation()

  if (!isOpen) return null

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalBody>
        <div>Информация по событию</div>
        <br />
        {t('response_code_kpk')}: {currentEntity.response_code}
        <br />
        {t('result_status_kpk')}: {currentEntity.result_status ?? 'Не получено'}
        <br />
        <details>
          <summary>{t('request_data_kpk')}:</summary>
          <Card>
            <pre>{JSON.stringify(currentEntity.request_data, null, 2)}</pre>
          </Card>
        </details>
        <details>
          <summary>{t('response_data_kpk')}:</summary>
          <Card>
            <pre>{JSON.stringify(currentEntity.response_data, null, 2)}</pre>
          </Card>
        </details>
      </ModalBody>
    </Modal>
  )
}
