import React, { useState } from 'react'
import { Alert, Button, Input, Row, Spinner, Table } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useGetReplicasQuery } from 'src/services/monitoring'
import UiContent from 'src/Components/Common/UiContent'
import { formatDateTime } from 'src/utils'

const ReplicaSet = () => {
  const { t } = useTranslation()

  const [name, setName] = useState('')
  const [sortOrder, setSortOrder] = useState('-')

  const { data: replicas, isLoading, error } = useGetReplicasQuery()

  const fieldsDescription = [
    t('metadata_name'),
    t('createdAt'),
    t('replicas'),
    'Observed generation'
  ]

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center mt-5 mb-5'>
        <Spinner color='primary' />
      </div>
    )
  }

  if (error) {
    return (
      <Alert
        color='warning'
        className='d-flex justify-content-center mt-5 mb-5'
      >
        <strong> {JSON.stringify(error)} </strong>
      </Alert>
    )
  }

  if (!replicas) {
    return null
  }

  const filteredReplicas = replicas.filter((r) =>
    r.metadata.name.includes(name)
  )

  const sortedReplicas = [...filteredReplicas].sort((a, b) => {
    const dateA = new Date(a.metadata.creationTimestamp).getTime()
    const dateB = new Date(b.metadata.creationTimestamp).getTime()

    if (sortOrder === 'asc') {
      return dateB - dateA
    } else if (sortOrder === 'desc') {
      return dateA - dateB
    } else {
      return 0
    }
  })

  const clearInputs = () => {
    setName('')
    setSortOrder('-')
  }

  return (
    <div className='d-flex flex-column gap-30'>
      <UiContent />
      <Row className='d-flex ms-1'>
        <Input
          type='text'
          className='form-control form-control-sm me-3'
          style={{ width: '200px', height: '30px' }}
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <Input
          type='select'
          className='form-select form-select-sm me-3'
          style={{ width: '200px', height: '30px' }}
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
        >
          <option value='-'>По умолчанию</option>
          <option value='desc'>desc</option>
          <option value='asc'>asc</option>
        </Input>
        <Button
          color='primary'
          style={{
            backgroundColor: '#405189',
            width: '200px',
            padding: '4px'
          }}
          type='submit'
          onClick={clearInputs}
        >
          {t('Clear')}
        </Button>
      </Row>

      <Table
        hover
        className='table-sm align-middle table-nowrap mb-0 mt-4'
        responsive
      >
        <thead>
          <tr>
            {fieldsDescription.map((fieldName) => (
              <th className='ps-3' key={fieldName} scope='col'>
                {fieldName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedReplicas.map((replica) => (
            <tr key={replica.metadata.name}>
              <td className='ps-3'>{replica.metadata.name}</td>
              <td className='ps-3'>
                {formatDateTime(replica.metadata.creationTimestamp)}
              </td>
              <td className='text-center pe-5'>{replica.status.replicas}</td>
              <td style={{ paddingLeft: '80px' }}>
                {replica.status.observedGeneration}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default ReplicaSet
