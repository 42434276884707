import { useTranslation } from 'react-i18next'

import Loader from '../Loader'
import { useGetCollectionStageHistoryQuery } from 'src/services/loans'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'
import List from './List'

type Props = {
  entityId: string
  entityType: string
  error: string | undefined
  icon: null | string
  title: string
  visible: boolean
}

export const LoanHistory = ({ entityId, visible }: Props) => {
  const { t } = useTranslation()

  const {
    data: collectionStage,
    isLoading,
    error
  } = useGetCollectionStageHistoryQuery(
    {
      loanId: entityId
    },
    { skip: !visible }
  )

  const fieldsDescription = {
    userId: {},
    createdAt: { isDate: true },
    beginDate: { isDate: true },
    endDate: { isDate: true },
    collectionStageId: {}
  }

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return <DangerAlert error={error} />
  }

  if (!collectionStage || (collectionStage && collectionStage.length === 0)) {
    return (
      <dl className='row mb-0'>
        <small>{t('No data')}</small>
      </dl>
    )
  }

  return (
    <List
      fieldsDescription={fieldsDescription}
      list={collectionStage}
      hover={true}
    />
  )
}
